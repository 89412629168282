.sign-in-intro {
  padding: 100px;
}

.sign-in-logo {
  display: inline-block;
  width: 100%;
}

.sign-in-logo img {
  height: 100px;
}

.sign-in-form {
  position: relative;
  padding: 0 20px;
  min-height: 600px;
  width: 100%;
  clear: both;
}

.sign-in .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sign-in {
  background: #00adef;
  background: linear-gradient(135deg, #00adef 0%, #0076e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adef', endColorstr='#0076e5', GradientType=1);
  position: relative;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  margin: 0px auto;
  padding: 0px auto;
}

*::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

/* Definition Lists */
dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

/* Table */
th {}

a:hover {
  text-decoration: none;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-menu {
  border-radius: 10px;
}

/* scroller */
.scroller {
  overflow-y: auto;
  scrollbar-color: var(--iq-primary) var(--iq-light-primary);
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: var(--iq-primary);
}

.scroller::-webkit-scrollbar-track {
  background-color: var(--iq-light-primary);
}

#sidebar-scrollbar {
  overflow-y: auto;
  scrollbar-color: var(--iq-primary) var(--iq-light-primary);
  scrollbar-width: thin;
}

#sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--iq-primary);
}

#sidebar-scrollbar::-webkit-scrollbar-track {
  background-color: var(--iq-light-primary);
}

/*chat style */
.chatHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: red;
  padding: 5px 10px;
  height: 66px;
}

.chatHeader__hash {
  color: red;
  font-size: 15px;
  padding: 10px;
}

.chatHeader__left>h5 {
  display: flex;
  align-items: center;
}

.chatHeader__right {
  display: flex;
  align-items: center;
  flex: 0.4;
  justify-content: space-evenly;
  margin-left: 8em;
  padding-left: 6em;
}

.chatHeader__right>.MuiSvgIcon-root {
  padding: 5px;
  cursor: pointer;
}

.chatHeader__right>.MuiSvgIcon-root:hover {
  color: white;
}

.sidebarChannel__hash {
  font-size: 30px;
  padding: 5px;
}

.sidebarChannel>h4 {
  display: flex;
  position: relative;
  padding-left: 15px;
  align-items: center;
  color: black;
  cursor: pointer;
}

.sidebarChannel:hover>h4 {
  color: black;
  cursor: pointer;
  background-color: #dadada59;
}

.load {
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.load>a>img {

  object-fit: contain;
}

.load__item {
  font-size: 1.5em;
  margin: 0px auto
}

.chat__messages {
  flex: 1;
  overflow: scroll;
}

.chat__messages::-webkit-scrollbar {
  display: none;
}

.chat__messages {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* MENSAJE */

.message {
  display: flex;
  margin: 0px auto;
  padding: 5px 20px 5px 20px;
  color: black;

}

.message__info {
  margin-left: 30px;
  position: relative;
  height: 30px;
  width: auto;
  border: 5px solid #87cfec;
  background: #87cfec;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.message__info>p {
  padding-bottom: 10px;
}

.message__timestamp {
  color: white;
  font-size: x-small;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-left: 15px;
}

.Emojis {
  cursor: pointer;
  background: transparent;
  border: none;
}

.contentFile {
  position: relative;
  z-index: 10;
}

.file {
  position: absolute;
  top: 0;
  left: 0px;
  opacity: 0;
  width: 30%;
  z-index: 20;
  height: 30px;
}



.btn-success-bt {
  background: #478862 !important;
  color: white !important; 
  border: none !important;
}

.btn-success-bt:hover {
  background: #19452b !important;
  color: white !important;
}

.btn-danger-bt {
  background: #b43828 !important;
  color: white !important; 
  border: none !important;
}

.btn-danger-bt:hover {
  background: #4a0b0b !important;
  color: white !important;
}

#dropDown {
  opacity: 0;
}

#dropDown:hover {
  opacity: 10;
}

.contenedorProgressBar {
  position: absolute;
  top: 18em;
  left: 5em;
  bottom: 0;
  margin-bottom: 30px;
  height: 53px;
  margin-right: 10px;
  width: 80%;
  background-color: transparent;
  z-index: 51;
  border-radius: 5px;
}

.progressText {
  text-align: center;
  display: grid;
}

#meet iframe{width:100vw !important;height:100vh !important;}
.content-page{padding:0;}
.padding90{padding-top:90px;}
.iq-sidebar{background: #ddf4fa;}
.iq-sidebar-menu .iq-menu li a{color: #663387;}
.chat-data {
  background: url(../public/images/page-img/chat1.jpg) repeat scroll center center;
  background-size: auto;
}
.iconoazul{    color: #50b5ff;
  border: 0;
  background: transparent;
  cursor: pointer !important;}
.iconoazul:hover{color:#663387;}
.margintop15{margin-top: 15px;}

.sign-in{
  background: url(../public/images/page-img/bglogin.jpg) no-repeat scroll left bottom,linear-gradient(#663387, #663387);
  background-size: cover;
}
.register{
  background: url(../public/images/page-img/bgregister.jpg) no-repeat scroll left bottom,linear-gradient(#663387, #663387);
  background-size: cover;
}
.todoh{height: 100vh;padding: 0;}
.todoh .sign-in-form{background: #fff;}

.select1 select{
  outline: #663387;
  border: 1px
px
solid #663387;
  border-radius: 4px;
}
.zitop{z-index: 99999;}
.formview b{display: block;}

.profile-header .profile-detail h4{color: #fff;
  text-shadow: 1px 1px 2px black;
  font-weight: 500;}

  .iq-friend-request{
    max-height: 300px;
    overflow-y: auto;
    height: auto;
  }
  .btn-primary{
    background: #50b5ff;
      border-color: #50b5ff;
  }
  .btn-danger{
    background: #F94176;
    border-color: #F94176;
  }
  
  .table-primary, .table-primary>td, .table-primary>th {
    background-color: #ddf4fa;
  }/*Ir a clase btn btn-sm btn-primary rounded mt-1*/

  .ulnone{
    list-style: none;
  }

.profile-feed-items li a.nav-link{text-transform: none;}

.exercises input[type=text]{    
  border: 1px solid #663387;
  height: 25px;
  border-radius: 4px;
}

.profile-header-image .cover-container{max-height: 150px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .margintop15 {margin-top: 0;}
}


@media (min-width: 768px) and (max-width: 991px) {
  .img-adapt {
    width: 40% !important;
  }
}

@media (min-width: 1px) and (max-width: 288px) {
  .img-adapt {
    width: 100% !important;
  }
}

/*Courses*/
.infinite-scroll-component{
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.course-filter .filter-btn{
  margin: 0;
  padding: 0;
}
.course-filter .filter-btn li{
  display: inline-block;
  font-weight: 700;
  color: var(--heading);
  padding: 5px 15px;
  background-color: transparent;
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.course-filter .filter-btn li.active,
.course-filter .filter-btn li:hover{
  background-color: #50b5ff;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(89, 86, 233, 0.2);
          box-shadow: 0px 10px 30px 0px rgba(89, 86, 233, 0.2);
}

.course-item{
  text-align: center;
  margin-bottom: 1rem;
}
.course-item a{cursor: pointer;}

.course-item li{
  list-style: none;
}
.course-item img{
  display: block;
  margin: auto;
}

.course-item span{
  color: #50b5ff;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-top: 0.5rem;
}

.modal-course-preview video{
  width: 100%;
  height: 300px;
  min-height: 300px;
}

.counter-container{
  text-align: center;
}

.counter-container i{
  font-size: 70px;
}

.counter-container span, .counter-container a{
  display: block;
  font-size: 20px;
}

.media-support-user-img img {
  height: 60px;
  width: 60px;
}

.profile-img img {
  height: 130px !important;
}